import { RemixBrowser } from '@remix-run/react'
import LogRocket from 'logrocket'
import { startTransition } from 'react'
import { hydrateRoot } from 'react-dom/client'

if (ENV.MODE === 'production' && ENV.SENTRY_DSN) {
	import('./utils/monitoring.client.tsx').then(({ init }) => init())
}

if (ENV.MODE === 'production' && typeof window !== 'undefined') {
	LogRocket.init(ENV.LOGROCKET_ID)
}

startTransition(() => {
	hydrateRoot(document, <RemixBrowser />)
})
